<template>
  <div class="yoyo-content">
    <img class="yoyo-content__figure" alt="ヨーヨー202403マン" src="../assets/image/yoyo.gif">
    <p class="yoyo-content__title">ヨーヨー202403マン</p>
    <p class="yoyo-content__desc">3秒後にリダイレクトします...</p>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  mounted() {
    setTimeout(() => {
      window.location.href = 'https://twitter.com/qqpcj';
    }, 3000); // 3秒後にリダイレクト
  }
}
</script>
<style>
.yoyo-content__figure {
  height: 200px;
  width: 200px;
}

.yoyo-content__title {
  font-size: 1.4rem;
  font-weight: bold;
}

.yoyo-content__desc {
  font-size: 0.8rem;
  color: #888;
}
</style>
