import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvKMjXjPqDytps-HkAgBoCeSLG70vQTT8",
  authDomain: "yoyo202403-proje.firebaseapp.com",
  projectId: "yoyo202403-proje",
  storageBucket: "yoyo202403-proje.appspot.com",
  messagingSenderId: "785452626564",
  appId: "1:785452626564:web:f059fbf4b8daac08a608d1",
};

// Initialize Firebase
initializeApp(firebaseConfig);

createApp(App).use(router).mount("#app");
